/*
* Api helper File
* Use for all Api.
**/
import axios  from 'axios';
import Cookies from 'js-cookie';
//
const MainUrl         = "https://apis.youtubeboosters.com/public/api/v1/";
//export const MainUrl         = "http://127.0.0.1:8000/api/v1/";
export const Base_URL = "https://youtubeboosters.com/";
//export const Base_URL = "http://localhost:3000/";

//
//Post Data Functions.
export function PostData(url,data) {
      // body..
     //
//      console.log(MainUrl+url);
      var headers = {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'accept': 'application/json',
                'X-localization':'en'
            }
      return axios.post(MainUrl+url,data,{headers: headers}).then((response) => {
            //console.log(res);
            //console.log(res.data);
            return response.data;
          }).catch((error) => {

            //       console.log(error);return false;
            //     let errorStatus = JSON.parse(JSON.stringify(error.response));
            //     //console.log(errorStatus.data);
            //     return errorStatus;
            const errorStatus = '';
            if (error.response) {
                  // console.log('Response data:', error.response.data);
                  errorStatus = error.response.data;
            } else if (error.request) {
                  // console.log('No response received:', error.request);
                  errorStatus = error.request;
            } else {
                  // console.log('Error creating request:', error.message);
                  errorStatus = error.message;
            }
            console.log(errorStatus);
            //return errorStatus;
          });
    }
//Get Data Functions.
export function GetData(url,data) {
	// body...
 
  var headers = {
            'Content-Type': 'application/json',
            //'Authorization':'Bearer '+Token,
            //'X-localization':'en'
        }
        //console.log("headers="+JSON.stringify(headers));
  return axios.get(MainUrl+url,data).then(res => {
        //console.log(res);
        //console.log(res.data);
        return res.data
      }).catch((error) => {
            let errorStatus = JSON.parse(JSON.stringify(error.response));
            //console.log(errorStatus.data);
            return errorStatus;
      });
}
//Post Data with token
//Post Data Functions.
export function PostDataWithToken(url,data) {
      // body..
      //
      var headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'accept': 'application/json',
            'X-localization':'en',
            'Authorization': 'Bearer '+Cookies.get("_token"),
      }
      //console.log(MainUrl+url);return false;
      return axios.post(MainUrl+url,data,{headers: headers}).then((response) => {
            //console.log(res);
            //console.log(res.data);
            return response.data;
      }).catch((error) => {
            let errorStatus = '';
            if (error.response) {
                  // console.log('Response data:', error.response.data);
                  let errorStatus = error.response.data;
            } else if (error.request) {
                  // console.log('No response received:', error.request);
                  let errorStatus = error.request;
            } else {
                  // console.log('Error creating request:', error.message);
                  let errorStatus = error.message;
            }
            //let errorStatus = JSON.parse(JSON.stringify(error.response));
            //console.log(errorStatus.data.errors[0]);
            return errorStatus;
      });
}

//Post Data Functions.
export function PostImageDataWithToken(url,data) {
      // body..
      var headers = {
            'Content-Type': 'multipart/form-data;',
            'Access-Control-Allow-Origin': '*',
            'accept': 'application/json',
            'X-localization':'en',
            'Authorization': 'Bearer '+Cookies.get("_token"),
      }
      return axios({
            method: "post",
            url:MainUrl+url,
            data: data, // FormData object instance with uploading file field
            timeout: 60000,
            headers: headers,
      }).then((response) => {
            //console.log(response);return false;
            return response.data;
      }).catch((error) => {
            let errorStatus = '';
            if (error.response) {
                  // console.log('Response data:', error.response.data);
                  errorStatus = error.response.data;
            } else if (error.request) {
                  // console.log('No response received:', error.request);
                  errorStatus = error.request;
            } else {
                  // console.log('Error creating request:', error.message);
                  errorStatus = error.message;
            }
            //let errorStatus = JSON.parse(JSON.stringify(error.response));
            //console.log(errorStatus.data.errors[0]);
            return errorStatus;
      });
}
//Get Data with token Functions.
export function GetDataWithToken(url,tokendata,data) {

      let config = {
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'accept': 'application/json',
            'X-localization':'en',
            'Authorization': 'Bearer '+Cookies.get("_token"),
        },
        params: {},
      }
   
    //console.log("headers="+JSON.stringify(headers));
  	return axios.get(MainUrl+url,config).then(res => {
        //console.log(res);
        return res.data
      }).catch((error) => {
            let errorStatus = '';
            if (error.response) {
                  // console.log('Response data:', error.response.data);
                  errorStatus = error.response.data;
            } else if (error.request) {
                  // console.log('No response received:', error.request);
                  errorStatus = error.request;
            } else {
                  // console.log('Error creating request:', error.message);
                  errorStatus = error.message;
            }
            console.log(errorStatus);
            return errorStatus;
      });
  
}

//Get Single Data with token Functions.
export function GetSingleDataWithToken(url,data) {
      // body..
      var headers = {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'accept': 'application/json',
            'X-localization':'en',
            'Authorization': 'Bearer '+Cookies.get("_token"),
      }
      //console.log(MainUrl+url);return false;
      return axios.post(MainUrl+url,data,{headers: headers}).then((response) => {
            //console.log(res);
            //console.log(res.data);
            return response.data;
      }).catch((error) => {
            let errorStatus = '';
            if (error.response) {
                  // console.log('Response data:', error.response.data);
                  errorStatus = error.response.data;
            } else if (error.request) {
                  // console.log('No response received:', error.request);
             errorStatus = error.request;
            } else {
                  // console.log('Error creating request:', error.message);
                  errorStatus = error.message;
            }
            //let errorStatus = JSON.parse(JSON.stringify(error.response));
            //console.log(errorStatus.data.errors[0]);
            return errorStatus;
      });
}

