import React from "react";
import PhoneInput from "react-phone-input-2";
import "../CommonComponents/Common.css";
import "react-phone-input-2/lib/style.css";

const CommonPhoneInput = (props) => {
  return (
    <div>
      {/* {props.label && <label className="inputLabel">{props.label}</label>} */}
      <PhoneInput
        placeholder={props.placeholder || ""}
        type={props.type || "number"}
        country={props.country || "in"}
        countryCodeEditable={props.countryCodeEditable || false}
        tabindex="1"
        maxLength="10"
        value={props.value}
        onChange={props.onChange}
        disabled={props.disabled || false}
      />
    </div>
  );
};

export default CommonPhoneInput;
