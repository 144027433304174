import { Grid } from '@mui/material'
import React, { useState } from 'react'
import CommonPhoneInput from '../../CommonComponents/CommonPhoneInput';

const CreateTicketHome = () => {
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);

            // You can now send this formData to your backend API for processing
            // For example, using fetch or axios
            // Example using fetch:
            /*
                  fetch('/upload', {
                    method: 'POST',
                    body: formData
                  })
                  .then(response => {
                    // Handle response
                  })
                  .catch(error => {
                    // Handle error
                  });
                  */
        } else {
            alert("Please select a file.");
        }
    };
    return (
        <main className='main-container'>
            <div className='main-title'>
                <h3>Support Ticket</h3>
                
            </div>
            <div className='invite_everyone_card' style={{ paddingTop: 20, paddingBottom: 20 }}>
                <label style={{ marginTop: 10 }}>Category</label>
                <select className="form-select ">
                    <option selected>General</option>
                    <option value="1">Payments</option>
                    <option value="2">Coins</option>
                    <option value="3">Withdrawal</option>
                </select>
                <label style={{ marginTop: 10 }}>Section</label>
                <select className="form-select ">
                    <option selected>Add-ons</option>
                    <option value="1">Website</option>
                </select>
                <label style={{ marginTop: 20 ,marginBottom:10}}>WhatsApp Number</label>
                <div className="common_phoneinput_border">
                    <CommonPhoneInput
                        //   value={numberName}
                        countrycodeditable={"false"}
                    //   onChange={(phone) => {
                    //     setNumberName("+" + phone);
                    //     setNumberNameError(false);
                    //   }}
                    />
                </div>
                {/* <div className="form-floating"> */}
                <label style={{ marginTop: 15 }}>Ticket details</label>
                <textarea className="form-comment"
                    id="floatingTextarea2"
                    style={{ height: 100 }}></textarea>
                <div style={{marginTop:20,marginBottom:20}}>
                    <input type="file" onChange={handleFileChange} />
                    <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={handleUpload}
                    >
                        Upload
                    </button>
                </div>
                <button type="button"
                    class="btn btn-primary"
                    style={{ marginTop: 10 }}>
                    Create
                </button>
            </div>
        </main>
    )
}

export default CreateTicketHome