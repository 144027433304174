import React, { useState } from "react";
import Topbar from "../global/Topbar";
import '../global/TopSideBar.css'
import Sidebar from "../global/Sidebar";
import ChangePasswordHome from "./ChangePasswordHome";

const ChangePassword = (props) => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
      <div className='withOut_childsideBar_grid_container'>
        <Topbar OpenSidebar={OpenSidebar} {...props}/>
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
          {...props}
        />
        <ChangePasswordHome />
      </div>
    )
  }

export default ChangePassword;
