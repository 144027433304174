import React, { useState, useEffect } from "react";
import { BsJustify }
  from 'react-icons/bs'
import { FaCoins } from "react-icons/fa";
import { GiWallet } from "react-icons/gi";
import { BiMoneyWithdraw } from "react-icons/bi";
import { MdOutlineHome } from "react-icons/md";
import { GoBell } from "react-icons/go";
import user from '../Assets/user1.png'
import './TopSideBar.css'
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import { FaCrown } from "react-icons/fa";

import {
  GetDataWithToken,
  PostDataWithToken,
  PostImageDataWithToken,
} from "../../SiteKey/Api/ApiHelper";

const Topbar = (props) => {
  const navigate = useNavigate();
  const [notificationDialog, setNotificationDialog] = useState(false)
  const [notificatios, setNotificatios] = useState([]);
  const [isPremium,setisPremium] = useState((localStorage.getItem("package") !== undefined) ? JSON.parse(localStorage.getItem("package")).package_id !== 1 ? true : false : false)
  const [isPremiumId,setisPremiumId] = useState((localStorage.getItem("package") !== undefined) ? JSON.parse(localStorage.getItem("package")).package_id : false)
  const [membershipType,setPackages] = useState()

  //
  const GetRecordDetailMemberhsip = () => {
    let Endpoints = "packages";
    // console.log("endpoints:"+Endpoints);
    GetDataWithToken(Endpoints, "", "")
      .then((response) => {
        //console.log('response type='+JSON.stringify(response));return false;
        response = JSON.parse(JSON.stringify(response));
        //
        if (response?.success) {
        //   console.log(response.data?.packages);
          const res = response.data?.packages
          res?.map((data,key)=>{
            if(data.id === isPremiumId){
              setPackages(data.title)
            }
          })
        }
      })
      .catch((err) => {
        setPackages([]);
      });
};

useEffect(() => {
  GetRecordDetailMemberhsip();
}, []);


  const NotificationHandle = () => {
    setNotificationDialog(!notificationDialog);
  };
  const handleClick = event => {
    localStorage.clear(); 
    localStorage.setItem("loggedin", false);
    localStorage.removeItem('loggedin');
    localStorage.removeItem('_token');
    localStorage.removeItem('user_name');
    localStorage.removeItem('user_id');
    localStorage.removeItem('user');
    //
    googleLogout();
    //
    navigate('/');
  };
  const GetRecordDetails = () => {
    let Endpoints = "get-header-data";
    // console.log("endpoints:"+Endpoints);
    GetDataWithToken(Endpoints, "", "")
      .then((response) => {
        //console.log('response type='+JSON.stringify(response));return false;
        response = JSON.parse(JSON.stringify(response));
        //console.log(response.data);return false;
        if (response?.success) {
          //console.log(response.data);
          localStorage.setItem('wallet_amount',response.data?.wallet_amount);
          localStorage.setItem('walletcoins',response.data?.wallet_coins);
          setNotificatios(response.data?.notifications);
          // console.log("notificatios=>",response.data?.notifications)
          localStorage.setItem("notifications",JSON.stringify(response.data?.notifications))
        }
      })
      .catch((err) => {
        console.log('error is response=>',err)
      });
  };
  useEffect(() => {
    GetRecordDetails();
  }, []);

  return (
    <header className='header'>
      <div className='menu-icon'>
        <BsJustify className='icon' onClick={props.OpenSidebar} />
      </div>
      <div className='header-left'>
        <div className="flex-align-center">
          <div className="icon_Container" onClick={() => {
            props.navigate('/redeem-coupons')
          }}>
            <FaCoins className='red_icon' />
            <div className="red_16_text">
              {localStorage.getItem('walletcoins')}
            </div>
          </div>
          <div className="icon_Container" onClick={() => {
            props.navigate('/topup-balance')
          }}>
            <GiWallet className='red_icon' />
            <div className="red_16_text">
              Rs.{Number(localStorage.getItem('wallet_amount')).toFixed(2)}
            </div>
          </div>
          <div className="icon_Container" onClick={() => {
            props.navigate('/withDrawal')
          }}>
            <BiMoneyWithdraw className='red_icon' />
            <div className="red_16_text">
              withdrawal
            </div>
          </div>

        </div>
      </div>
      <div className='header-middle'>
        <MdOutlineHome className='home_red_icon' 
                        onClick={() => {
                            props.navigate('/')
                        }}/>
      </div>
      <div className='header-right'>
        <div className='bell_popup_container'>
          <span> {  notificatios.length }</span>
          <GoBell className='heder_right_white_icon' onClick={NotificationHandle} />
          {notificationDialog && (
            <div className='bell_popup'>
              {notificatios.map((capmaign) => (
                <div style={{ marginTop: 10 }}  key={capmaign?.id}>
                  <Grid container spacing={2}>
                    <Grid item md={10} sm={12} xs={12}>
                      <div className="flex-align-center">
                        <GoBell className="notification_black_icon" style={{ color: '#000', fontSize: 25 }} />
                        <div className="pl-2">
                          <div style={{ color: '#000', fontSize: 15 }}>{capmaign?.notification?.subject}</div>
                          <div style={{ color: 'grey', fontSize: 12 }}>
                            {capmaign?.notification?.message}
                          </div>
                        </div>
                      </div>
                    </Grid>
                    <Grid item md={2} sm={12} xs={12}>
                      <div style={{ color: '#000', fontSize: 10 }}>Notification</div>
                    </Grid>
                  </Grid>
                </div>
              ))}  
              {/*<div style={{ marginTop: 10 }} className="view-all">
                <Grid container spacing={2}>
                  <Grid item md={12} sm={12} xs={12}>
                    <div className="w-100">
                      <div className="pl-2">
                        <div className="notification-center" style={{ color: '#000', fontSize: 15 }}>
                          <a className="dropdown-item" onClick={() => {
                            props.navigate('/notification')
                          }}>View All</a>
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>*/}
            </div>
          )}
        </div>

        <div className="header_right_flex">
          <div className="nav-item dropdown">
            <label
              className="nav-link dropdown-toggle header_parameter_color header_user_lineheight position-relative d-flex justify-content-center"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <img src={user} alt="" style={{ width: '30px', height: '30px' }} />
              <div className="position-absolute" style={{top:"-20px",left:"auto",right:"auto"}}>{isPremium && <FaCrown color="white" size={20} />}</div>
            {/* side */}
            </label>
            <div className="membershipType text-center">{membershipType}</div>
            <div className="dropdown-menu">
              <div style={{ fontSize: 14, fontWeight: "bold" }}>
                {localStorage.getItem("user_name")}
              </div>
              {/* <div style={{ fontSize: 12 }}>
                siddiquifaizan859
              </div> */}
              {/* <div className='profile_text_container'>
                <div className='profile_text'>My Profile</div>
              </div>
              <div className='profile_text_container'>
                <div className='profile_text'>Account Information</div>
              </div>
              <div className='profile_text_container'>
                <div className='profile_text'>Change Password</div>
              </div>
              <div className='profile_text_container'>
                <div className='profile_text'>Logout</div>
              </div> */}
              <li>
                <a className="dropdown-item" onClick={() => {
                  props.navigate('/profile')
                }}>
                  My Profile
                </a>
              </li>
              <li>
                <a className="dropdown-item" onClick={() => {
                  props.navigate('/account-information')
                }}>
                  Account Information
                </a>
              </li>
              {/* <li>
                <a className="dropdown-item" onClick={() => {
                  props.navigate('/change-password')
                }}>
                  Change Password
                </a>
              </li> */}
              <li>
                <a className="dropdown-item" onClick={handleClick}>
                  Logout
                </a>
              </li>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Topbar;
