import React from 'react'
import UnAuthRoute from './UnAuthRoute'
import { useLocation, useNavigate } from 'react-router-dom';
// import AuthRoute from './AuthRoute';

const ValidateFlow = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
         {/* <AuthRoute
                {...props}
                navigate={navigate}>
            </AuthRoute> */}
            <UnAuthRoute
                {...props}
                navigate={navigate}
                location={location}>
            </UnAuthRoute>
        </>
    )
}

export default ValidateFlow