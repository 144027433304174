import React from "react";

const ChangePasswordHome = () => {
  return (
    <main className="main-container">
      <div className="main-title">
        <h3>Change Password</h3>
      </div>
      <div className="card">
        <div>
          <div style={{ marginTop: 20, color: "#fff" }}>Old password</div>
          <input
            type="text"
            className="form-select"
            placeholder="Enter old password"
            aria-label="Amount (to the nearest dollar)"
          />
        </div>
        <div>
          <div style={{ marginTop: 20, color: "#fff" }}>New password</div>
          <input
            type="text"
            className="form-select"
            placeholder="Enter new password"
            aria-label="Amount (to the nearest dollar)"
          />
        </div>
        <div>
          <div style={{ marginTop: 20, color: "#fff" }}>
            Confirm password
          </div>
          <input
            type="text"
            className="form-select"
            placeholder="Enter Confirm password"
            aria-label="Amount (to the nearest dollar)"
          />
        </div>

        <button
          type="button"
          className="btn btn-primary"
          style={{ marginTop: 20, alignSelf: "flex-start" }}
          onClick={() => {
            // props.navigate("/createCapmaignNext")
          }}
        >
          Change Password
        </button>
      </div>
    </main>
  );
};

export default ChangePasswordHome;
