import React from "react";
import ValidateFlow from "./ValidateFlow/ValidateFlow";

// import { ThemeProvider } from "styled-components";
function App(props) {
  // const theme = {
  //   media: { mobile: "768px", tab: "998px" },
  // };
  return (
    <>
      {/* <ThemeProvider theme={theme}> */}
        <ValidateFlow {...props} />
      {/* </ThemeProvider> */}
    </>
  );
}

export default App;
