import React from 'react'
import '../global/TopSideBar.css'

const ChildSideBarEarnCoins = (props) => {
    return (
        <aside id="childSideBar">
            {/* <div className='flex-space-between'>
        <span className='icon close_icon' onClick={props.OpenSidebar}>X</span>
    </div> */}
            {/* {(props.location.pathname ====
        "/myCapmaign" || '/createCapmaign') && ( */}
            <ul className='child-sidebar-list'>
                <li className='child-sidebar-list-item'>
                    <a onClick={() => {
                        props.navigate('/earn-coins')
                    }}>
                        <div className={
                            props.location.pathname === "/earn-coins"
                                ? "child_sidebar_red_text"
                                : "child_sidebar_white_text"
                        }>buy coins</div>
                    </a>
                </li>
                <li className='child-sidebar-list-item'>
                    <a onClick={() => {
                        props.navigate('/membership')
                    }}>
                        <div className={
                            props.location.pathname === "/membership"
                                ? "child_sidebar_red_text"
                                : "child_sidebar_white_text"
                        }>Membership</div>
                    </a>
                </li>
                <li className='child-sidebar-list-item'>
                    <a onClick={() => {
                        props.navigate('/topup-balance')
                    }}>
                        <div className={
                            props.location.pathname === "/topup-balance"
                                ? "child_sidebar_red_text"
                                : "child_sidebar_white_text"
                        }>Add balance</div>
                    </a>
                </li>
            </ul>
            {/* )} */}
        </aside>
    )
}

export default ChildSideBarEarnCoins