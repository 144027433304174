import React, { useState } from 'react'
import Sidebar from '../global/Sidebar'
import '../global/TopSideBar.css'
import Topbar from '../global/Topbar'
import CreateTicketHome from './CreateTicketHome'
import ChildSideBarContact from './ChildSideBarContact'

const CreateTicket = (props) => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className='grid-container'>
            <Topbar OpenSidebar={OpenSidebar} {...props}/>
            <Sidebar
                openSidebarToggle={openSidebarToggle}
                OpenSidebar={OpenSidebar}
                {...props} >
            </Sidebar>
            <ChildSideBarContact openSidebarToggle={openSidebarToggle}
                OpenSidebar={OpenSidebar}
                {...props}></ChildSideBarContact>
            <CreateTicketHome {...props}/>
        </div>
    )
}

export default CreateTicket