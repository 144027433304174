import { createSlice } from '@reduxjs/toolkit'

const initialState = 10;

export const TopUpBalance = createSlice({
  name: 'balance',
  initialState,
  reducers: {
    topup_balance: (state) => {
      return state
    },
  },
})

// Action creators are generated for each case reducer function
export const { topup_balance } = TopUpBalance.actions

export default TopUpBalance.reducer