import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import CreateTicket from "../Components/Ticket/CreateTicket";
import BuyCoins from "../Components/BuyCoins/BuyCoins";
import Profile from "../Components/Profile/Profile";
import AccountInformation from "../Components/AccountInformation/AccountInformation";
import ChangePassword from "../Components/ChangePassword/ChangePassword";
import { ProtectedRoutes } from "../Services/ProtectedRoutes";

const Home = React.lazy(() => import("../Components/Home/Home"));
const Support = React.lazy(() => import('../Components/HOC/Footer/Support/Support'))
const TermsConditions = React.lazy(() => import('../Components/HOC/Footer/Terms&Conditions/Terms_Conditions'))
const PrivacyPolicy = React.lazy(() => import('../Components/HOC/Footer/PrivacyPolicy/Privacy_Policy'))
const ShippingPolicy = React.lazy(() => import('../Components/HOC/Footer/ShippingPolicy/Shipping_Policy'))
const CancellationRefundPolicy = React.lazy(() => import('../Components/HOC/Footer/CancellationRefundPolicy/Cancellation_Refund_Policy'))
const AboutUs = React.lazy(() => import('../Components/HOC/Footer/AboutUs/About_Us'))
const GetInTouch = React.lazy(() => import('../Components/HOC/Footer/Get_In_Touch/Get_In_Touch'))
const Login = React.lazy(() => import('../Components/UnAuth/Login'))
const Register = React.lazy(() => import('../Components/UnAuth/Register'))
const ForgetPassword = React.lazy(() => import('../Components/UnAuth/ForgetPassword'))
const ResetPassword = React.lazy(() => import('../Components/UnAuth/ResetPassword'))
const Pricing = React.lazy(() => import('../Components/HOC/NormalHeader/Pricing'))
const Dashboard = React.lazy(() => import('../Components/Dashboard/Dashboard'))
const IncrementDecrement = React.lazy(() => import('../Components/IncrementDecrement/IncrementDecrement'))
const Affiliates = React.lazy(() => import('../Components/Affiliates/Affiliates'))
const Ticket = React.lazy(() => import('../Components/Ticket/Ticket'))
const EarnCoins = React.lazy(() => import('../Components/EarnCoins/EarnCoins'))
const EarnCoinsCheckout = React.lazy(() => import('../Components/EarnCoinsCheckout/EarnCoinsCheckout'))
const Membership = React.lazy(() => import('../Components/Membership/Membership'))
const MembershipCheckout = React.lazy(() => import('../Components/MembershipCheckout/MembershipCheckout'))
const TopUpBalance = React.lazy(() => import('../Components/TopUpBalance/TopUpBalance'))
const RedeemCoupons = React.lazy(() => import('../Components/RedeemCoupons/RedeemCoupons'))
const Rewards = React.lazy(() => import('../Components/Rewards/Rewards'))
const AnalyticalReport = React.lazy(() => import('../Components/AnalyticalReport/AnalyticalReport'))
const MyCapmaign = React.lazy(() => import('../Components/MyCapmaign/MyCapmaign'))
const Extension = React.lazy(() => import('../Components/Extension/Extension'))
const CreateCapmaign = React.lazy(() => import('../Components/CreateCapmaign/CreateCapmaign'))
const BuyWorker = React.lazy(() => import('../Components/BuyWorker/BuyWorker'))
const BuyBoostep = React.lazy(() => import('../Components/BuyBoostep/BuyBoostep'))
const SellYourPoints = React.lazy(() => import('../Components/SellYourPoints/SellYourPoints'))
const BuyMonetized = React.lazy(() => import('../Components/BuyMonetized/BuyMonetized'))
const WithDrawal = React.lazy(() => import('../Components/WithDrawal/WithDrawal'))
const Wallet = React.lazy(() => import('../Components/Wallet/Wallet'))
const CreateCapmaignNext = React.lazy(() => import('../Components/CreateCapmaignNext/CreateCapmaignNext'))
const RazorPay = React.lazy(() => import('../Components/RazorPay/RazorPay'))
const BuyMonetizedCheckout = React.lazy(() => import('../Components/BuyMonetizedCheckout/BuyMonetizedCheckout'))
const BuyCoinsCheckout = React.lazy(() => import('../Components/BuyCoinsCheckout/BuyCoinsCheckout'))
const BuyWorkersCheckout = React.lazy(() => import('../Components/BuyWorkersCheckout/BuyWorkersCheckout'))

const CheckoutForm = React.lazy(() => import('../Components/Checkout/CheckoutForm'))
const NotificationHome = React.lazy(() => import('../Components/Notification/NotificationHome'))


const UnAuthRoute = (props) => {
    return (
        <Suspense >
            <Routes>
                <Route path="/" element={<Home {...props} />} />
                <Route path="/support" element={<Support {...props} />} />
                <Route path="/terms-condition" element={<TermsConditions {...props} />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy {...props} />} />
                <Route path="/shipping-policy" element={<ShippingPolicy {...props} />} />
                <Route path="/cancellation-refund-policy" element={<CancellationRefundPolicy {...props} />} />
                <Route path="/about-us" element={<AboutUs {...props} />} />
                <Route path="/get-in-touch" element={<GetInTouch {...props} />} />
                <Route path="/login" element={<Login {...props} />} />
                <Route path="/register" element={<Register {...props} />} />
                <Route path="/forget-password" element={<ForgetPassword {...props} />} />
                <Route path="/reset-password" element={<ResetPassword {...props} />} />
                <Route path="/pricing" element={<Pricing {...props} />} />

                 <Route path="/" element={<ProtectedRoutes/>}> 
                    <Route path="/profile" element={<Profile {...props} />} />
                    <Route path="/account-information" element={<AccountInformation {...props} />} />
                    <Route path="/change-password" element={<ChangePassword {...props} />} />
                    <Route path="/dashboard" element={<Dashboard {...props} />} />
                    <Route path="/affiliates" element={<Affiliates {...props} />} />
                    <Route path="/ticket" element={<Ticket {...props} />} />
                    <Route path="/create-ticket" element={<CreateTicket {...props} />} />
                    <Route path="/earn-coins" element={<EarnCoins {...props} />} />
                    <Route path="/membership" element={<Membership {...props} />} />
                    <Route path="/membership-checkout" element={<MembershipCheckout {...props} />} />
                    <Route path="/topup-balance" element={<TopUpBalance {...props} />} />
                    <Route path="/redeem-coupons" element={<RedeemCoupons {...props} />} />
                    <Route path="/rewards" element={<Rewards {...props} />} />
                    <Route path="/analytical-report" element={<AnalyticalReport {...props} />} />
                    <Route path="/my-capmaign" element={<MyCapmaign {...props} />} />
                    <Route path="/extension" element={<Extension {...props} />} />
                    <Route path="/create-capmaign" element={<CreateCapmaign {...props} />} />
                    <Route path="/buy-worker" element={<BuyWorker {...props} />} />
                    <Route path="/buy-workers-checkout" element={<BuyWorkersCheckout {...props} />} />
                    <Route path="/buy-boostep" element={<BuyBoostep {...props} />} />
                    <Route path="/sell-your-points" element={<SellYourPoints {...props} />} />
                    <Route path="/buy-monetized" element={<BuyMonetized {...props} />} />
                    <Route path="/buy-coins" element={<BuyCoins {...props} />} />
                    <Route path="/withdrawal" element={<WithDrawal {...props} />} />
                    <Route path="/wallet" element={<Wallet {...props} />} />
                    <Route path="/create-capmaign-next" element={<CreateCapmaignNext {...props} />} />
                    <Route path="/increment-decrement" element={<IncrementDecrement {...props} />} />
                    <Route path="/checkout-form" element={<CheckoutForm {...props} />} />
                    <Route path="/razor-pay" element={<RazorPay {...props} />} />
                    <Route path="/earn-coins-chackout" element={<EarnCoinsCheckout {...props} />} />
                    <Route path="/buy-monetized-checkout" element={<BuyMonetizedCheckout {...props} />} />
                    <Route path="/buy-coins-checkout" element={<BuyCoinsCheckout {...props} />} />
                    <Route path="/notifications" element={<NotificationHome {...props} />} />
                 </Route> 
            </Routes>
        </Suspense>
    )
}

export default UnAuthRoute