import { Grid } from '@mui/material'
import React, { useState, useEffect, useCallback } from "react";
import { GetDataWithToken } from "../../SiteKey/Api/ApiHelper";
import { PostDataWithToken } from "../../SiteKey/Api/ApiHelper";
import { useNavigate } from "react-router-dom";
import toastr from "toastr";
import 'toastr/build/toastr.min.css';


const BuyCoinsHome = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [buycoin, setBuyCoin] = useState([]);
  const navigate = useNavigate();
  //
  const handleClick = useCallback( (event:any) => {
    // console.log("buy_coin_id = "+event.currentTarget.dataset.id);
    localStorage.setItem('buy_coin_id',event.currentTarget.dataset.id);

 
    setIsLoading(true);
    const Endpoints = "check-buycoin";
    const dt = {"buy_coin_id":event.currentTarget.dataset.id};
    const data = JSON.stringify(dt);
    //console.log("Form Data = "+JSON.stringify(data));return false;
    PostDataWithToken(Endpoints, data).then((response) => {
        setIsLoading(false);
        response = JSON.parse(JSON.stringify(response));
        // console.log(response);
        if (response.success) {
             navigate("/buy-coins-checkout");
             setIsLoading(false);
        } else {
            console.log("error =" + response);
            //
            toastr.error(response.message, "", {
              closeButton: true,
              progressBar: true,
              timeOut: 3000,
              positionClass: "toast-top-right",
              showMethod: "fadeIn",
              hideMethod: "fadeOut",
            })
        }
    });
  });
  //
  const GetRecordDetails = () => {
    let Endpoints = "buy-coin-data";
    // console.log("endpoints:"+Endpoints);
    GetDataWithToken(Endpoints, "", "")
      .then((response) => {
        //console.log('response type='+JSON.stringify(response));return false;
        response = JSON.parse(JSON.stringify(response));
        //
        if (response?.success) {
          console.log(response.data?.buy_coins);
          setBuyCoin(response.data?.buy_coins);
        }
      })
      .catch((err) => {
        setBuyCoin([]);
      });
  };

  useEffect(() => {
      GetRecordDetails();
  }, []);
  return (
    <main className="main-container">
      <div className="main-title">
        <h3>Buy Coins</h3>
      </div>
      <Grid container spacing={2}>
        <Grid item sm={12} md={12} xs={12}>
          <div className='card_container'>
            <div className='card_white_text'>History</div>
          </div>
        </Grid>
      </Grid>
      <div
        className="invite_everyone_card"
        style={{ paddingTop: 20, paddingBottom: 20 }}
      >
        <div className="container w-100">
           <table className="table w-100">
              <thead>
                  <tr>
                      <th>Name</th>
                      <th>Coins</th>
                      <th>Prices</th>
                      <th>Buy</th>
                  </tr>
              </thead>
              <tbody>
                  {buycoin.map((coin) => (
                      <tr key={coin?.id}>
                          <th style={{ backgroundColor: 555 }}>{coin.user?.name}</th>
                          <th>{coin?.coins}</th>
                          <th>Rs. {coin?.amount}</th>
                          <th>
                            {isLoading ? <button type="button" className="btn btn-primary" style={{ marginTop: 10 }}>
                                Wait...
                            </button> : <button type="button" class="btn btn-primary btn-sm"  data-id={coin?.id} type="button" onClick={handleClick}>Buy</button>}
                            
                          </th>
                      </tr>
                  ))}
              </tbody>
          </table>
          
        </div>
      </div>
    </main>
  );
};

export default BuyCoinsHome;
