import React, { useState } from "react";
import Topbar from "../global/Topbar";
import ProfileHome from "./ProfileHome";
import '../global/TopSideBar.css'
import Sidebar from "../global/Sidebar";

const Profile = (props) => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
      setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
      <div className='withOut_childsideBar_grid_container'>
        <Topbar OpenSidebar={OpenSidebar} {...props}/>
        <Sidebar
          openSidebarToggle={openSidebarToggle}
          OpenSidebar={OpenSidebar}
          {...props}
        />
        <ProfileHome />
      </div>
    )
  }

export default Profile;
