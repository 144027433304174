import React from 'react'
import '../global/TopSideBar.css'

const ChildSideBarContact = (props) => {
    return (
        <aside id="childSideBar">
            {/* <div className='flex-space-between'>
        <span className='icon close_icon' onClick={props.OpenSidebar}>X</span>
    </div> */}
            <ul className='child-sidebar-list'>
                <li className='child-sidebar-list-item'>
                    <a onClick={() => {
                        props.navigate('/ticket')
                    }}>
                        <div className={
                            props.location.pathname === "/ticket"
                                ? "child_sidebar_red_text"
                                : "child_sidebar_white_text"
                        }>Support Ticket</div>
                    </a>
                </li>
            </ul>
        </aside>
    )
}

export default ChildSideBarContact