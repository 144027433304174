import { createSlice } from '@reduxjs/toolkit'

const initialState = 10;

export const changeTheNumber = createSlice({
  name: 'counter',
  initialState,
  reducers: {
    increment: (state) => {
      return state+= 1
    },
    decrement: (state) => {
        return state -= 1
    },
    incrementByAmount: (state, action) => {
        return state += action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { increment, decrement, incrementByAmount } = changeTheNumber.actions

export default changeTheNumber.reducer