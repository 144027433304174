import React, { useState } from 'react'
import Sidebar from '../global/Sidebar'
import '../global/TopSideBar.css'
import Topbar from '../global/Topbar'
import BuyCoinsHome from './BuyCoinsHome'
// import ChildSideBarShop from '../BuyWorker/ChildSideBarShop'
import ChildSideBarEarnCoins from '../EarnCoins/ChildSideBarEarnCoins'

const BuyCoins = (props) => {
    const [openSidebarToggle, setOpenSidebarToggle] = useState(false)

    const OpenSidebar = () => {
        setOpenSidebarToggle(!openSidebarToggle)
    }
    return (
        <div className='grid-container'>
            <Topbar OpenSidebar={OpenSidebar} {...props}/>
            <Sidebar
                openSidebarToggle={openSidebarToggle}
                OpenSidebar={OpenSidebar}
                {...props} >
            </Sidebar>
            <ChildSideBarEarnCoins openSidebarToggle={openSidebarToggle}
                OpenSidebar={OpenSidebar}
                {...props}>
            </ChildSideBarEarnCoins>
            <BuyCoinsHome {...props}/>
        </div>
    )
}

export default BuyCoins