import React from "react";
import Avatar from "react-avatar";

const ProfileHome = (props) => {
  
  return (
    <main className="main-container">
      <div className="main-title">
        <h3>PROFILE</h3>
      </div>

      <div className="card">
        <div style={{ color: "#fff" }}>User Profile</div>
        <div className="flex-space-between" style={{ marginTop: 20 }}>
          <div className="flex-align-center">
            <div>
              <Avatar
                className="profileAvtar"
                name={localStorage.getItem('user_name')}
                size="65"
                // round="10"
                src={""}
              />
            </div>
            <div style={{ marginLeft: 40 }}>
              <h5 style={{ color: "#fff" }}>{localStorage.getItem('user_name')}</h5>
              <h8 style={{ color: "#fff" }}>{localStorage.getItem('email')}</h8>
            </div>
          </div>
          <div className="flex-align-center">
            <div className="today_center" style={{ marginRight: 60 }}>
              <div style={{ color: "#fff" }}>0</div>
              <div style={{ color: "#fff" }}>Today's Views</div>
            </div>
            <div className="today_center">
              <div style={{ color: "#fff" }}>5,576</div>
              <div style={{ color: "#fff" }}>Total Views</div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ProfileHome;
